import { Game, PlayerGame, Absolute, Coordinate } from '../interfaces'
import { possibleMoves, doesPieceHaveMove } from '../helpers/moves'
import { equalCoordinates } from './coordinates'

const blueStairs: Coordinate<Absolute> = [0, 2]
const redStairs: Coordinate<Absolute> = [4, 2]

// Checks to see if the target player's king is in danger
// or if the opponent's king is about to touch your stairs
export function isInCheck(player: PlayerGame, game: Game): boolean {
  const targetStairs = player.color === 'blue' ? blueStairs : redStairs

  let theirKingCanTouchOurStairs: boolean = false
  let kingLocation: Coordinate<Absolute> | null = null
  let allPossibleMoves: Coordinate<Absolute>[] = []

  const otherPlayer =
    game.players[0].color === player.color ? game.players[1] : game.players[0]

  game.board.forEach((row, x) => {
    row.forEach((piece, y) => {
      if (!piece) return

      const location = [x, y] as Coordinate<Absolute>

      // We found the master, save its location. We will come back
      // to this at the end of the loop to see if they are in danger
      if (piece.color === player.color && piece.type === 'master') {
        kingLocation = location
      }

      // We came across one of our opponents pieces, get all of its
      // moves which we will use later
      if (piece.color !== player.color) {
        const piecesMoves = possibleMoves(otherPlayer, game, location)

        allPossibleMoves = [...allPossibleMoves, ...piecesMoves]

        // We found their master, check if they can touch our stairs
        if (piece.type === 'master') {
          theirKingCanTouchOurStairs = !!piecesMoves.find(
            equalCoordinates(targetStairs)
          )
        }
      }
    })
  })

  const isKingInDanger =
    !!kingLocation && !!allPossibleMoves.find(equalCoordinates(kingLocation))

  return theirKingCanTouchOurStairs || isKingInDanger
}
