import { Coordinate, Absolute, Relative } from '../interfaces'

export function translateCoordinate(
  absolute: Coordinate<Absolute>,
  relative: Coordinate<Relative>
): Coordinate<Absolute> | null {
  const x = absolute[0] + relative[0]
  const y = absolute[1] + relative[1]

  if (x < 0 || x > 4 || y < 0 || y > 4) return null

  return [x, y] as Coordinate<Absolute>
}

type Abs = Coordinate<Absolute>

export function equalCoordinates(one: Abs): ((two: Abs) => boolean)
export function equalCoordinates(one: Abs, two: Abs): boolean
export function equalCoordinates(one: Abs, two?: Abs) {
  return two
    ? one[0] === two[0] && one[1] === two[1]
    : (two: Abs) => one[0] === two[0] && one[1] === two[1]
}
